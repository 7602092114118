import React, { useEffect, useState } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import tw, { css } from 'twin.macro'
import { useHeaderContext } from '../context/Header'

import { LocationFinder } from './LocationFinder'

import { flatListToHierarchical } from '../helpers'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const ActivePageIndicator = () => {
  const { isCommercial } = useHeaderContext()
  return (
    <div
      css={[
        tw`h-1 w-10 lg:(w-2/3) mt-3 bg-yellow-600 absolute`,
        `left: 0;
      @media(min-width: 1023.98px) { 
          & { left: calc((100% - 66.66%) / 2); 
        }
      }`,
        isCommercial && tw`bg-blue-600`,
      ]}
    />
  )
}

const NavItem = ({ item, firstChild }) => {
  const [open, setOpen] = useState(false)
  const toggleOpen = () => setOpen(!open)

  const closeDropdownOnExternalClick = React.useCallback(
    e => {
      if (!e.target.closest('.dropdown-menu') && open) {
        setOpen(false)
      }
    },
    [open]
  )

  /* event listener to close the location pane if the user clicks ouside it */
  useEffect(() => {
    document.addEventListener('click', closeDropdownOnExternalClick)
    return () =>
      document.removeEventListener('click', closeDropdownOnExternalClick)
  }, [open])

  const hasChildren = item.children.length
  const { isCommercial, location, commercialPestControl } = useHeaderContext()

  const destination = item.connectedNode?.node.uri || item.path
  const currentPage =
    location?.pathname === destination ||
    location?.href === destination ||
    (destination === '/commercial/' && commercialPestControl)

  const linkStyles = [
    'white-space: nowrap;',
    tw`leading-4 block w-full text-left py-6 transition ease-in-out duration-100 border-t border-blue-400
            hover:(cursor-pointer)
            lg:(border-0 px-3 py-2)
            xl:(px-4)
            relative`,
    isCommercial
      ? tw`text-blue-600
                hover:(text-blue-500)
                focus:(text-blue-500)`
      : tw`text-gray-100 border-gray-500
                hover:(text-yellow-600)
                focus:(text-yellow-600)`,
    firstChild && tw`border-0`,
  ]

  const destinationIsExternal = false //(destination.startsWith('http') && !destination.includes(location.origin)) || destination === undefined;

  return (
    <li tw="relative">
      {hasChildren ? (
        <>
          <button
            css={[
              ...linkStyles,
              open && isCommercial && tw`text-blue-600`,
              open && !isCommercial && tw`text-yellow-600`,
            ]}
            onClick={toggleOpen}
          >
            {item.description ? item.description : null}
            {item.label}
            <FontAwesomeIcon
              icon={['fas', 'chevron-down']}
              size="sm"
              css={[
                tw`hidden ml-2 transform rotate-0 lg:(rotate-0 inline-block)`,
                open && tw`rotate-180`,
              ]}
            />
          </button>
          <DropdownMenu
            items={item.children}
            open={open}
            toggleOpen={toggleOpen}
          />
        </>
      ) : (
        <>
          {destinationIsExternal ? (
            <a css={linkStyles} href={destination}>
              {item.description ? (
                <FontAwesomeIcon icon={['far', item.description]} tw="mr-2" />
              ) : null}
              {item.label}
            </a>
          ) : (
            <Link
              css={linkStyles}
              to={destination}
              id={commercialPestControl && 'commercial-nav-item'}
            >
              {item.description ? (
                <FontAwesomeIcon icon={['far', item.description]} tw="mr-2" />
              ) : null}
              {item.label}
              <FontAwesomeIcon
                icon={['fas', 'chevron-right']}
                size="xs"
                tw="ml-2 lg:(hidden)"
              />
              {currentPage && <ActivePageIndicator />}
            </Link>
          )}
        </>
      )}
    </li>
  )
}

const DropdownMenu = ({ items, open, toggleOpen }) => {
  const { isCommercial, location } = useHeaderContext()

  return (
    <div
      css={[
        `left: 50%;`,
        tw`transition ease-in-out duration-150 transform lg:(-translate-x-1/2 absolute bg-white p-4 w-64 rounded-lg shadow-lg mt-4)`,
        tw`block lg:(hidden pointer-events-none opacity-0 translate-y-1)`, // Closed state
        open &&
          tw`block lg:(block opacity-100 pointer-events-auto translate-y-0)`, // open state
      ]}
      className="dropdown-menu"
      aria-expanded={open}
    >
      <div
        css={[
          tw`hidden lg:(block) absolute w-0 h-0 border-transparent border-l-8 border-r-8 border-b-8 -translate-x-1/2`,
          `border-bottom-color: #fff; bottom: 100%; left: 50%;`,
        ]}
      ></div>
      <div tw="flex flex-col-reverse">
        <ul>
          {items.map((child, i) => {
            const destination = child.connectedNode?.node.uri || child.path
            const currentPage =
              location?.pathname === destination ||
              location?.href === destination
            return (
              <Link
                key={`link-child-${i}`}
                tabIndex={open ? 0 : -1}
                css={[
                  isCommercial
                    ? tw`text-blue-600`
                    : tw`text-gray-100 hover:(text-gray-800 rounded)`,
                  tw`block p-4 lg:(text-gray-800 py-2 px-5  transition ease-in-out duration-100) relative my-2
                                          hover:(bg-yellow-600)
                                          focus:(bg-yellow-600)
                                          active:(bg-yellow-500)`,
                  currentPage && tw`bg-yellow-600 text-blue-800 rounded`,
                ]}
                to={destination}
              >
                {child.label}
                <FontAwesomeIcon
                  icon={['fas', 'chevron-right']}
                  size="xs"
                  tw="ml-2 lg:(hidden)"
                />
              </Link>
            )
          })}
        </ul>
        <div tw="text-right">
          <button
            tabIndex={open ? 0 : -1}
            css={[
              tw`hidden lg:(inline) p-2 leading-4 text-4xl text-yellow-600 cursor-pointer focus:(outline-none)
                            hover:(text-yellow-500)
                            focus:(text-yellow-500 shadow-outline)`,
            ]}
            onClick={toggleOpen}
          >
            ×
          </button>
        </div>
      </div>
    </div>
  )
}

const SkipNavLink = () => {
  return (
    <Link
      to="#main"
      tw="absolute opacity-0 pointer-events-none z-50 px-4 py-1 text-sm bg-yellow-600 rounded
            focus:(opacity-100 pointer-events-auto)"
    >
      Skip Main Navigation
    </Link>
  )
}

const Navbar = ({ open, toggleOpen }) => {
  const queryResult = useStaticQuery(graphql`
    {
      headerMenu: wpMenu(slug: { eq: "header" }) {
        menuItems {
          nodes {
            key: id
            parentId
            label
            description
            connectedNode {
              node {
                ... on WpContentNode {
                  uri
                }
              }
            }
            path
          }
        }
      }
      commercialHeaderMenu: wpMenu(slug: { eq: "header-commercial" }) {
        menuItems {
          nodes {
            key: id
            parentId
            label
            description
            connectedNode {
              node {
                ... on WpContentNode {
                  uri
                }
              }
            }
            path
          }
        }
      }
    }
  `)

  const { isCommercial } = useHeaderContext()
  const menuItems = isCommercial
    ? queryResult?.commercialHeaderMenu?.menuItems
    : queryResult?.headerMenu?.menuItems

  const navItems = flatListToHierarchical(menuItems.nodes)

  return (
    <nav
      css={[
        'top:100%;',
        tw`flex items-center hidden absolute w-full
                lg:(border-l-8 border-yellow-600 relative px-2 py-0 flex top-auto w-auto)
                xl:(border-0 ml-16)`,
        isCommercial
          ? tw`bg-yellow-600 lg:(border-l-8 border-blue-600)`
          : tw`bg-blue-600 lg:(border-l-8 border-yellow-600)`,
        open && tw`flex`,
      ]}
      aria-label="Main Site"
    >
      <SkipNavLink />
      <div
        css={[
          `right: 100%; background: linear-gradient(-62deg, transparent 40%, ${
            isCommercial ? '#194798' : '#ffd100'
          } 0 60%, #fff 60%);`,
          tw`hidden absolute h-full w-20 xl:(block)`,
          isCommercial ? tw`bg-yellow-600` : tw`bg-blue-600`,
        ]}
        // aria-role="presentation"
      />
      <ul
        tw="flex flex-col w-full lg:(flex-row)"
        aria-label="Navigation Links"
        css={[
          css`
            @media (max-width: 1023px) {
              & li {
                padding-left: 30px;
                padding-right: 30px;
              }
            }
          `,
        ]}
      >
        <LocationFinder mobile tw="flex md:(hidden)" />
        {navItems.map((item, i) => (
          <NavItem
            key={`nav-item-${item.slug}-${i}`}
            item={item}
            firstChild={i === 0}
          />
        ))}
      </ul>
    </nav>
  )
}

export default Navbar
